import { createReducer } from "@reduxjs/toolkit";

const initialState= {
    login                     : {sts:false,msg:"Inti call",rslt:[],error:[]},
    change_password           : {sts:false,msg:"Inti call",rslt:[],error:[]},
    forgot_password           : {sts:false,msg:"Inti call",rslt:[],error:[]},
    verify_auth_totp          : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_nav_menu              : {sts:false,msg:"Inti call",rslt:[],error:[]},
    is_grands                 : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_common_drop           : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_schema                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    search                    : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_by_id                 : {sts:false,msg:"Inti call",rslt:[],error:[]},
    add_update                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    delete_ids                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    user_get_info             : {sts:false,msg:"Inti call",rslt:[],error:[]},
    user_permission_add_upd   : {sts:false,msg:"Inti call",rslt:[],error:[]},
    reset_password_auth       : {sts:false,msg:"Inti call",rslt:[],error:[]},
    module_sort_list          : {sts:false,msg:"Inti call",rslt:[],error:[]},
    
    module_sort_submit        : {sts:false,msg:"Inti call",rslt:[],error:[]},
    // generate_secret_key       : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_by_id_for_auth        : {sts:false,msg:"Inti call",rslt:[],error:[]},
    main_menu_sort            : {sts:false,msg:"Inti call",rslt:[],error:[]},
    sort_submit               : {sts:false,msg:"Inti call",rslt:[],error:[]},
    submenu_sort              : {sts:false,msg:"Inti call",rslt:[],error:[]},
    submenu_sort_submit       : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_expense_line_header   : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_expense_line          : {sts:false,msg:"Inti call",rslt:[],error:[]},
    get_expense_line_filter   : {sts:false,msg:"Inti call",rslt:[],error:[]},
    expenses_line_reject      : {sts:false,msg:"Inti call",rslt:[],error:[]},
    expenses_sts_update       : {sts:false,msg:"Inti call",rslt:[],error:[]},
    user_bank_info            : {sts:false,msg:"Inti call",rslt:[],error:[]},
    view_lot_create           : {sts:false,msg:"Inti call",rslt:[],error:[]},
    create_lot                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    search_lot                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    search_lot_expense_line   : {sts:false,msg:"Inti call",rslt:[],error:[]},
    download_payment          : {sts:false,msg:"Inti call",rslt:[],error:[]},
    update_payment_status     : {sts:false,msg:"Inti call",rslt:[],error:[]},
    consolidate_report        : {sts:false,msg:"Inti call",rslt:[],error:[]},
    delete_exp                : {sts:false,msg:"Inti call",rslt:[],error:[]},
    expense_line_report       : {sts:false,msg:"Inti call",rslt:[],error:[]},
    twoway_authentication     : {sts:false,msg:"Inti call",rslt:[],error:[]},

    role_get_info             : {sts:false,msg:"Inti call",rslt:[],error:[]},
    role_permission_add_upd   : {sts:false,msg:"Inti call",rslt:[],error:[]},
}

const returnData=(state,action)=>{
    var callFrm = action.type.split("/")[0];
    if(action.payload){
        var result  = action.payload;
        Object.keys(result).map((key_val)=>{
            state[callFrm] = {...state[callFrm],[key_val] : result[key_val]}
            return true
        })
    }else{
        state[callFrm] = {sts:false,msg:"Inti payload fail",rslt:[],error:[]}
    }
}

const reducers = createReducer(initialState,(builder)=>{ builder
    .addCase("login/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("change_password/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("forgot_password/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("verify_auth_totp/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_nav_menu/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("is_grands/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_common_drop/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_schema/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("search/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_by_id/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("add_update/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("delete_ids/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("user_get_info/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("user_permission_add_upd/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("reset_password_auth/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("module_sort_list/fulfilled",(state,action)=>{ returnData(state,action) })

    .addCase("module_sort_submit/fulfilled",(state,action)=>{ returnData(state,action) })
    // .addCase("generate_secret_key/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_by_id_for_auth/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("main_menu_sort/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("sort_submit/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("submenu_sort/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("submenu_sort_submit/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_expense_line_header/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_expense_line/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("get_expense_line_filter/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("expenses_line_reject/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("expenses_sts_update/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("user_bank_info/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("view_lot_create/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("create_lot/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("search_lot/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("search_lot_expense_line/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("download_payment/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("update_payment_status/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("consolidate_report/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("delete_exp/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("expense_line_report/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("twoway_authentication/fulfilled",(state,action)=>{ returnData(state,action) })

    .addCase("role_get_info/fulfilled",(state,action)=>{ returnData(state,action) })
    .addCase("role_permission_add_upd/fulfilled",(state,action)=>{ returnData(state,action) })
})
export default reducers;
