import React            from "react";
import moment           from "moment";
import * as XLSX        from "xlsx"

// MATERIAL UI
import CircularProgress from "@mui/material/CircularProgress"
import Grid             from "@mui/material/Grid"



// SPINNER FOR PAGE
export const Spinner=()=>{
    return(
        <div className="fallback-spinner">
            <Grid container sx={{textAlign:"center"}}>
                <Grid item xs={12} md={12} sx={{padding:"15px"}}>
                    <CircularProgress style={{color:"#f75940"}}/>
                </Grid>
            </Grid>
        </div>
    )
}

// SPINNER FOR PAGE SMALL
export const SpinnerSmall=()=>{
    return(
        <div className="fallback-spinner-small">
            <Grid container sx={{textAlign:"center"}}>
                <Grid item xs={12} md={12} sx={{padding:"15px"}}>
                    <CircularProgress style={{color:"#f75940"}}/>
                </Grid>
            </Grid>
        </div>
    )
}

// RANDOM ID
export function random_id(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for(var i=0; i<length;i++){
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
}

// GET TABLE HEADER FROM SCHEMA
export const get_tbl_header=(schema_info,pro_info)=>{
    const tbl_col = [];
    if(schema_info.sts){
        var schema_rslt = schema_info.rslt;
        for (var col_name in schema_rslt){
            var display_name = schema_rslt[col_name].name;
            var show_col     = schema_rslt[col_name].show_col;
            var is_ref       = schema_rslt[col_name].is_ref;
            var sort_order   = schema_rslt[col_name].sort_order;
            var type         = schema_rslt[col_name].type;
            if((show_col) && (display_name)){
                if(is_ref){
                    tbl_col.push({
                        headerName : display_name,
                        field      : col_name,
                        tbl_order  : sort_order,
                        sortable   : true,
                        flex       : !pro_info?window.innerWidth>780?1:null:null,
                        renderCell : handelRowdata({select:is_ref.select})
                    });
                }else{
                    if(type === "Date"){
                        tbl_col.push({
                            headerName : display_name,
                            field      : col_name,
                            tbl_order  : sort_order,
                            sortable   : true,
                            // flex       : 1,
                            flex       : !pro_info?window.innerWidth>780?1:null:null,
                            renderCell : !pro_info?(data)=>date_format(data.value):"date"
                        });
                    }else{
                        tbl_col.push({
                            headerName : display_name,
                            field      : col_name,
                            tbl_order  : sort_order,
                            // flex       : 1,
                            flex       : !pro_info?window.innerWidth>780?1:null:null,
                            sortable   : true,
                            renderCell : col_name==="status"?handelRowdata({select:col_name}):null
                        });
                    }
                }
            }
        }
        tbl_col.sort((a, b) => (a.tbl_order > b.tbl_order) ? 1 : -1)
    }
    return tbl_col
}

//DATE FORMAT
export function date_format(date,format=null){
    var change_date = "-"
    if(date){
        var change_formate = "DD-MMM-YYYY"
        if(format){
            change_formate = format
        }
        change_date = moment(date).format(change_formate);
        if(change_date === "Invalid date"){
            change_date = "-"
        }
    }
    return change_date;
}

// HANDLE RENDER CELL FOR ISREF
const handelRowdata = pro_data => row => {
    var select   = pro_data.select;
    var row_info = row.value;
    if((row_info) && (row_info[select])){
        return row_info[select]
    }else
    if(select==="status"){
        return row_info===1?"Active":"Inactive"
    }else
    {
        return "-"
    }
}



// CONVERTING FILE TO BASE 64 FORMAT
export const read_file=(file,file_type)=>{
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            // var file_rslt = fileReader.result;
            var file_rslt = fileReader.result.split(/base64,/)[1]
            var type      = file.type;
            if(file_type){
                type = file_type;
            }
            resolve({sts:true,msg:"file read success",fileName:file.name,size:file.size,type:type,body:file_rslt});
        };
        fileReader.onerror = () => {
            reject({sts:false,msg:"file read failed",fileName:"",size:"",type:"",body:""});
        };
        fileReader.readAsDataURL(file);
    });
}

// GET DATA WIDTH
const get_text_width=(text,type,editable,input_type,font = "14px -apple-system")=>{
    const canvas  = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font  = font;
    const metrics = context.measureText(text);
    if(type==="col_width"){
        if(editable){
            return Math.round(metrics.width+25);
        }else{
            return Math.round(metrics.width+28);
        }
    }else{
        if(editable){
            if(input_type==="numbers"){
                return Math.round(metrics.width+60);
            }else{
                return Math.round(metrics.width+35);
            }
        }else{
            return Math.round(metrics.width+15);
        }
    }
};

// CALCULATE COLUMN WIDTH
export const calculate_columns_width = (columns,source,maxWidthPerCell)=>{
    var editable = false;
    const columnsWithWidth = columns.map((column) =>{
        // for table with editable inputs
        if(column.editable){
            editable = column.editable
        }
        var width_value = Object.assign(column, {width: get_text_width(column.title_width_chk?column.title_width_chk:column.headerName,"col_width",editable)});
        return width_value
    });
  
    // verify if the cell value is bigger than the column width
    source.map((entry) => {
        columnsWithWidth.map((column, i) => {
            const columnWidth = column.width;
            // let cellValue     = Object.values(entry)[i];
            // if(editable){
                // data value is taken with column name
            let cellValue     = entry[column.field];
            // }
            const columnsParsed = JSON.stringify(cellValue);
    
            // Get the string width based on data length
            let cellWidth = get_text_width(columnsParsed,"data_width",editable,column.type);
    
            // Verify if the cell value is smaller than column's width
            if(cellWidth < columnWidth){
                cellWidth = columnWidth;
            }
    
            // Verify if the cell value width is bigger than our max width flag
            if(maxWidthPerCell){
                if(cellWidth > maxWidthPerCell){
                    cellWidth = maxWidthPerCell;
                }
            }

            // Verify for date columns with min width
            var minWidth = column.minWidth;
            if(minWidth){
                if(editable){
                    if(minWidth>cellWidth){
                        cellWidth = minWidth
                    }
                }else{
                    cellWidth = minWidth;
                }
            }
            if(column.fixWidth){
                cellWidth = column.fixWidth
            }
    
            // Update the column width
            columnsWithWidth[i].width = cellWidth;
            return true
        });
        return true
    });

    return columnsWithWidth;
};

export const export_excel=(data,fileName,header)=>{
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);
    if(header && header.length>0){
        XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
    }
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, fileName);
}