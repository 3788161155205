import React, { lazy,Suspense } from 'react';
import ReactDOM 				from 'react-dom/client';
import {Provider}               from "react-redux"
import store                    from "./redux/store/store";
import { Spinner }              from './assets/js/init';
import reportWebVitals 			from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// import useMediaQuery from '@mui/material/useMediaQuery';

import "./assets/css/init.css"
import "react-toastify/dist/ReactToastify.css"

const LazyApp = lazy(()=>import("./App"));
// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

const theme = createTheme({
	palette: {
		mode: 'light', //dark prefersDarkMode ? 'dark' : 'light',
		text: {
		  primary: '#00000', // #f9fafb dark
		},
		primary: {
		  main: '#3abc36',
		},
		secondary: {
		  main: '#ff8000', //rgba(255, 255, 255, 0.7) dark
		},
		error: {
		  main: '#d32f2f',
		},
		success: {
		  main: '#ff8000',
		  contrastText: '#FFFFFF',
		},		
		export: {
			main: '#f99706',
			light: 'rgb(250, 171, 55)',
			dark: 'rgb(174, 105, 4)',
			contrastText: '#FFFFFF',
		},
		import: {
			main: '#2196f3',
			light: '#64b5f6',
			dark: '#1976d2',
			contrastText: '#FFFFFF',
		},
	},
	spacing: 8,
	shape: {
		borderRadius: 4,
	},
	components: {
		MuiToolbar:{
			styleOverrides: {
				root: {minHeight: "60px !important"},
			},
		},
		// MuiPopover:{
		// 	styleOverrides: {
		// 		root: {minWidth: "145px !important",padding:"4px"},
		// 	},
		// },
		MuiTooltip:{
			defaultProps: {
				arrow: true,
			}
		},
		MuiList:{
			defaultProps: {
				dense: true,
			}
		},

		MuiGrid:{
			variants: [
				{
					props: {variant:'selectedMenu'},
					style: {
						color:"#ff8000",fontWeight:"bold"
					},
				},
			],
		},
		MuiMenuItem:{
			defaultProps: {
				dense: true,
			},
			variants: [
				{
					props: {variant:'selectedMenu'},
					style: {
						color:"#ff8000",fontWeight:"bold"
					},
				},
			],
		},
		MuiTab:{
			styleOverrides: {
				root: {textTransform:"capitalize",fontWeight:600},
			},
		},
		MuiTabPanel:{
			styleOverrides: {
				root: {padding: "10px"},
			},
		},
		MuiTable: {
			defaultProps: {
				size: 'small',
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {borderColor:"#E9E9E9"},
				withBorderColor:{
					borderColor:"#E1E1E1"
				},
				row:{
					cursor:"pointer",
					'&:hover': {
						fontWeight:500,
						// backgroundColor:"#f9f9f9",
					},
				},
				columnHeaderTitle:{
					fontWeight:600,
					overflow: "hidden",
					whiteSpace: "normal",
					lineHeight:"initial"
				},
				cellContent:{
					whiteSpace:"initial",
					overflowWrap:"break-word"
				},
				paper:{
					padding:"15px",
				}
			},
		},

		MuiButton:{
			styleOverrides: {
				root: {textTransform:"capitalize",fontWeight:600},
			},
			defaultProps: {
				size: 'medium',
				variant:"contained"
			}
		},
		MuiButtonGroup:{
			styleOverrides: {
				root: {textTransform:"capitalize",fontWeight:600},
			},
			defaultProps: {
				size: 'medium',
				variant:"contained"
			}
		},
		MuiLoadingButton:{
			defaultProps: {
				size: 'medium',
				variant:"contained"
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: 'small',
			}
		},
		MuiFab:{
			defaultProps: {
				size: 'small',
			}
		},
		
		
		MuiFormControl: {
			defaultProps: {
				fullWidth:true,
				margin: 'dense',
				size: 'small',
			}
		},
		MuiInputBase: {
			defaultProps: {
				margin: 'dense',
			}
		},
		MuiTextField: {
			defaultProps: {
				fullWidth:true,
				margin: 'dense',
				size: 'small',
				variant:"standard"
			},
		},
		MuiSelect:{
			defaultProps: {
				margin: 'dense',
				size: 'small',
				variant:"standard"
			}
		},
		MuiCheckbox:{
			defaultProps: {
				size: 'small',
			}
		},
		MuiRadio: {
			defaultProps: {
				size: 'small',
			}
		},
		MuiSwitch: {
			defaultProps: {
				size: 'small',
			}
		},
		MuiFormHelperText: {
			defaultProps: {
				margin: 'dense',
			}
		},		
		
		MuiInputLabel: {
			defaultProps: {
				margin: 'dense',
			}
		},		
		MuiAvatar:{
			styleOverrides: {
				root: {backgroundColor: "#e0e0e0",width: 32, height: 32},
			},
		}
	},
	typography: {
		h1: {
		  fontFamily: 'Mukta',
		  fontSize: 27,
		  fontWeight: 600,
		},
		h2: {
		  fontFamily: 'Mukta',
		  fontSize: 24,
		  fontWeight: 600,
		},
		h3: {
		  fontFamily: 'Mukta',
		  fontSize: 21,
		  fontWeight: 500,
		},
		h4: {
		  fontFamily: 'Mukta',
		  fontSize: 18,
		  fontWeight: 500,
		},
		h5: {
		  fontFamily: 'Mukta',
		  fontSize: 15,
		  fontWeight: 500,
		},
		h6: {
		  fontFamily: 'Mukta',
		  fontSize: 15,
		  fontWeight: 500,
		},
		subtitle1: {
		  fontFamily: 'Mukta',
		},
		subtitle2: {
		  fontFamily: 'Mukta',
		},
		body1: {
		  fontFamily: 'Mukta',
		},
		body2: {
		  fontFamily: 'Mukta',
		},
		button: {
		  fontFamily: 'Mukta',
		},
		caption: {
		  fontFamily: 'Mukta',
		},
		overline: {
		  fontFamily: 'Mukta',
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Suspense fallback={<Spinner />}>
			<ThemeProvider theme={theme}>
				<CssBaseline/>
				<LazyApp />
			</ThemeProvider>
		</Suspense>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();